import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Metcon Week`}</em></p>
    <p>{`6 Rounds for time of:`}</p>
    <p>{`2 D Ball Cleans over shoulder (80/40`}{`#`}{`)(rx+ 150/80`}{`#`}{`)`}</p>
    <p>{`4 Wall Walks`}</p>
    <p>{`8 Box Jumps (30/24″)`}</p>
    <p>{`16 Calorie Row`}</p>
    <p><strong parentName="p">{`*`}{`This Friday is “First Friday Hop” around Nulu.  We’re offering any
of our normally scheduled classes for free so invite a friend!`}</strong></p>
    <p><strong parentName="p">{`*`}{`This Saturday is Derby day and it’s also our monthly free workout at
9:00 & 10:00am so you can bring a friend this day as well!  We’ll have
just one hour of open gym on Derby day so we’ll close at 12:00.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      